import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';  
import { map } from  'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
// Injectable
export class CastVoteAPIService {

    // base_url = "http://64.227.58.231:5517/"; //test
    // base_url = "https://test-api.castvotegh.com/"; //LIVE LIVE
    base_url = "https://api.castvotegh.com/"; //LIVE LIVE
    cloudinary_upload_url: string = "https://api.cloudinary.com/v1_1/castvoteghana/image/upload";
    paypal_nodejs_base_url = "https://castvote-paypal.bigticketwave.com/api/";//"http://64.227.58.231:5524/api/";

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
    }
    
    constructor(private httpclient: HttpClient){}

    getResults(cat_id): Observable<any> {
        return this.httpclient.get(this.base_url + `voting_results?cat_id=${atob(cat_id)}&customer_number=`);
    }

    getBannerAds(): Observable<any> {
        return this.httpclient.get(this.base_url + `get_banner_ads`);
    }

    getAwards(): Observable<any> {
        return this.httpclient.get(this.base_url + `get_event_list?activity_type_code=VOT&event_class_code=HP`);
    }

    getTicketEvents(activity_type_code): Observable<any> {
        return this.httpclient.get(this.base_url + `get_event_list?activity_type_code=${activity_type_code}`);
    }

    getPlanDetails(plan_id): Observable<any> {
        return this.httpclient.get(this.base_url + "get_plan_details_web?plan_id=" + plan_id);
    }
    
    getCatPlanDetails(cat_id): Observable<any> {
        return this.httpclient.get(this.base_url + "get_plan_details_web_cat?cat_id=" + cat_id);
    }

    getEventPaymentDetails(val): Observable<any> {
        return this.httpclient.get(this.base_url + "get_event_payment_details?nom_id=" + val);
    }

    get_CatSubCatNomineesDetails(plan_id): Observable<any> {
        return this.httpclient.get(this.base_url + `get_cat_nominees?plan_id=${atob(plan_id)}&customer_number=`);
    }

    get_CatCatNomineesDetails(plan_id): Observable<any> {
        return this.httpclient.get(this.base_url + `get_cat_nominees?plan_id=${plan_id}&customer_number=`);
    }

    get_Categories(plan_id): Observable<any> {
        return this.httpclient.get(this.base_url + `get_categories?plan_id=${plan_id}`);
    }

    get_SubCat(cat_id): Observable<any> {
        return this.httpclient.get(this.base_url + `get_subcats_by_cat_id?cat_id=${cat_id}&customer_number=`);
    }

    get_NomineesDetails(cat_id): Observable<any> {
        // console.log(this.base_url + `get_nominees?cat_id=${atob(cat_id)}`);
        return this.httpclient.get(this.base_url + `get_nominees?cat_id=${atob(cat_id)}`);
    }

    OTPRequest(params): Observable<any> {
        // return this.httpclient.get(this.base_url + "get_nominees_by_subcat?vote_session_id=SSN_000000009&sub_cat_id=SCAT_000000042");
        return this.httpclient.post(this.base_url + "request_OTP", params);
    }

    ConfirmVoteRequest(params): Observable<any> {
        // return this.httpclient.get(this.base_url + "get_nominees_by_subcat?vote_session_id=SSN_000000009&sub_cat_id=SCAT_000000042");
        return this.httpclient.post(this.base_url + "confirm_votes", params);
    }

    getSubDivs(plan_id,customer_number): Observable<any> {
        return this.httpclient.get(this.base_url + `req_activity_divs_subs?plan_id=${plan_id}&customer_number=${customer_number}`);
    }

    getTicketClasses(plan_id,activity_time_desc,activity_date_desc,customer_number): Observable<any> {
        return this.httpclient.get(this.base_url + `req_activity_class?plan_id=${plan_id}&activity_time_desc=${activity_time_desc}&activity_date_desc=${activity_date_desc}&customer_number=${customer_number}`);
    }

    ProcessPaymentRequest(params): Observable<any> {
        return this.httpclient.post(this.base_url + "req_payment_req", params);
    }

    getNominationAwards(): Observable<any> {
        return this.httpclient.get(this.base_url + `get_nomination_awards`);
    }

    RequestNominationFee(params): Observable<any> {
        return this.httpclient.post(this.base_url + "req_nomination_fee", params);
    }

    RequestBulkVotingPackages(params): Observable<any> {
        return this.httpclient.post(this.base_url + "req_bulk_voting_package", params);
    }

    ReqCalculateBulkVoting(params): Observable<any> {
        return this.httpclient.post(this.base_url + "calculate_bulk_voting_vote", params);
    }

    RequestNominationFiling(params): Observable<any> {
        return this.httpclient.post(this.base_url + "req_file_nomination", params);
    }

    RequestChargeInfo(params): Observable<any> {
        return this.httpclient.post(this.base_url + "req_charge_info", params);
    }

    RequestTransactionVerification(params): Observable<any> {
        return this.httpclient.post(this.base_url + "req_client_transaction_details", params);
    }



    public fileUploadCloudinary(formData) {

        return this.httpclient.post<any>(this.cloudinary_upload_url, formData, {  
            reportProgress: true,  
            observe: 'events'  
        });  
    }

    PayPalCreatePaymentRequest(params): Observable<any> {
        return this.httpclient.post(this.paypal_nodejs_base_url + "pay", params);
    }

    PayPalSuccessRequest(params): Observable<any> {
        return this.httpclient.post(this.paypal_nodejs_base_url + "success", params);
    }


    FinalPayPalCallBackRequest(params): Observable<any> {
        return this.httpclient.post(this.base_url + "paypal_payment_callback_node", params);
    }

    ReprocessTransactionRequest(params): Observable<any> {
        return this.httpclient.post(this.base_url + "req_process_transaction", params);
    }
}